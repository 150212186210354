import React, { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";

import Text from "src/components/ui/Text";
import Loader from "src/components/ui/Loader";
import Confirm from "src/components/ui/Confirm";
import AppSelect from "src/components/form/AppSelect";
import AppButton, { ButtonColorTypes } from "src/components/form/AppButton";
import AppCheckbox from "src/components/form/AppCheckbox";

import NumberMapping from "src/common/consts/NumberMapping";
import { useCurrentUser } from "src/common/AuthProvider/authProvider.hooks";

import {
  CitTaxAmountTypes,
  CompanyTaxDetailsInput,
  FlatRateTaxAmountTypes,
  PitTaxTypeTypes,
  TaxTypes,
  VatTaxTypes,
} from "src/api/graphql/types-and-hooks";

import taxesSchema from "./taxes.schema";
import FinancialDetailsWrapper from "./FinancialDetailsWrapper";
import { useCompanyTaxDetails, useEditCompany } from "./financialDetails.hooks";

const Taxes = () => {
  const [showConfirm, setShowConfirm] = useState(false);

  const { t } = useTranslation();
  const TaxTypeOptions = Object.values(TaxTypes).map((c) => ({ label: t(c), value: c }));
  const CitTaxAmountOptions = Object.values(CitTaxAmountTypes)
    .map((c) => ({
      label: `${NumberMapping[c]}%`,
      value: c,
      number: NumberMapping[c],
    }))
    .sort((a, b) => a.number - b.number);
  const PitTaxTypeOptions = Object.values(PitTaxTypeTypes).map((c) => ({ label: t(c), value: c }));
  const FlatRateTaxAmountOptions = Object.values(FlatRateTaxAmountTypes)
    .map((c) => ({
      label: `${NumberMapping[c]}%`,
      value: c,
      number: NumberMapping[c],
    }))
    .sort((a, b) => a.number - b.number);

  const VatTaxTypesOptions = Object.values(VatTaxTypes).map((c) => ({ label: t(c), value: c }));

  const currentUser = useCurrentUser();
  const companyId = currentUser?.company._id;

  const { taxDetails, loading } = useCompanyTaxDetails(companyId);
  const {
    handleSubmit,
    register,
    control,
    getValues,
    watch,
    formState: { errors, isDirty },
  } = useForm({
    values: taxDetails,
    resolver: yupResolver(taxesSchema),
    reValidateMode: "onChange",
  });

  const { isUpdated, isUpdating, handleEditCompany } = useEditCompany();

  useEffect(() => {
    if (isUpdated) setShowConfirm(false);
  }, [isUpdated]);

  const onConfirm = () => {
    const data = getValues() as CompanyTaxDetailsInput;
    if (!data.isVatPayer) data.vatType = undefined;
    if (data.taxType !== TaxTypes.Cit) data.citTaxAmount = undefined;
    if (data.taxType !== TaxTypes.Pit) {
      data.pitTaxType = undefined;
      data.flatRateTaxAmount = undefined;
    }

    handleEditCompany({ id: companyId, taxDetails: { ...data } });
  };

  return (
    <>
      <Confirm
        show={showConfirm}
        title={t("Confirm-update-title")}
        onCancel={() => setShowConfirm(false)}
        onConfirm={onConfirm}
        loading={isUpdating}
      />
      <FinancialDetailsWrapper>
        <div className="flex flex-col space-y-5">
          <div className="flex justify-end">
            <div className="px-2">
              <AppButton
                title={t("Save")}
                onClick={handleSubmit(() => setShowConfirm(true))}
                color={ButtonColorTypes.PRIMARY}
                customClass="rounded-[8px] font-sf-pro-medium"
                textClass="text-white font-sf-pro-medium"
                disabled={!isDirty}
              />
            </div>
          </div>
          <div className="bg-white rounded-[20px] p-5">
            <div className="flex flex-col space-y-6">
              <Text className="2xl:text-20 xl:text-18 text-16 font-sf-pro-bold">{t("Taxes")}</Text>
              {loading ? (
                <Loader />
              ) : (
                <>
                  <div className="flex flex-col space-y-4">
                    <Text className="2xl:text-18 xl:text-16 text-12">{t("Select-tax-type")}</Text>
                    <div className="flex flex-row space-x-4">
                      <div className="w-[40%]">
                        <Controller
                          control={control}
                          name="taxType"
                          render={({ field }) => (
                            <AppSelect
                              options={TaxTypeOptions}
                              field={field}
                              placeholder="Wybierz..."
                              error={!!errors?.taxType}
                            />
                          )}
                        />
                      </div>
                      <div className="w-[40%]">
                        {watch("taxType") === TaxTypes.Cit ? (
                          <Controller
                            control={control}
                            name="citTaxAmount"
                            render={({ field }) => (
                              <AppSelect
                                options={CitTaxAmountOptions}
                                field={field}
                                placeholder="Wybierz..."
                                error={!!errors?.citTaxAmount}
                              />
                            )}
                          />
                        ) : undefined}
                      </div>
                    </div>
                  </div>
                  {watch("taxType") === TaxTypes.Pit ? (
                    <div className="flex flex-row space-x-4">
                      <div className="w-[40%]">
                        <Controller
                          control={control}
                          name="pitTaxType"
                          render={({ field }) => (
                            <AppSelect
                              options={PitTaxTypeOptions}
                              field={field}
                              placeholder="Wybierz..."
                              error={!!errors?.pitTaxType}
                            />
                          )}
                        />
                      </div>
                      <div className="w-[40%]">
                        {watch("pitTaxType") === PitTaxTypeTypes.FlatRate ? (
                          <Controller
                            control={control}
                            name="flatRateTaxAmount"
                            render={({ field }) => (
                              <AppSelect
                                options={FlatRateTaxAmountOptions}
                                field={field}
                                placeholder="Wybierz..."
                                error={!!errors?.flatRateTaxAmount}
                              />
                            )}
                          />
                        ) : undefined}
                      </div>
                    </div>
                  ) : undefined}
                  <div className="flex flex-col space-y-4">
                    <Text className="2xl:text-18 xl:text-16 text-12">{t("Vat-tax")}</Text>
                    <AppCheckbox id="isVatPayer" register={register} label={t("Vat-active-payer")} />
                    {watch("isVatPayer") === true ? (
                      <div className="w-[40%]">
                        <Controller
                          control={control}
                          name="vatType"
                          render={({ field }) => (
                            <AppSelect
                              options={VatTaxTypesOptions}
                              field={field}
                              placeholder="Wybierz..."
                              error={!!errors?.vatType}
                            />
                          )}
                        />
                      </div>
                    ) : undefined}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </FinancialDetailsWrapper>
    </>
  );
};

export default Taxes;
