import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import Loader from "src/components/ui/Loader";

import { useGetInvoiceFile } from "src/common/hooks/useGetInvoiceFile";

import { InvoiceGenres } from "src/api/graphql/types-and-hooks";

import TaxForm from "./forms/TaxForm";
import LoanForm from "./forms/LoanForm";
import InvoiceForm from "./forms/InvoiceForm";
import { useInvoice } from "./invoice.hooks";
import Text from "src/components/ui/Text";

const InvoiceEdit = () => {
  const { t } = useTranslation();
  const { invoiceId } = useParams();
  const { invoice, loading } = useInvoice(invoiceId);
  const { handleGetInvoiceInvoice } = useGetInvoiceFile();

  const [file, setFile] = useState<File | Blob>(null);
  const [formValues, setFormValues] = useState(null);

  useEffect(() => {
    if (invoice?.genre === InvoiceGenres.Car) {
      const { _id, car, ...rest } = invoice;
      setFormValues({ ...rest, car: car?._id });
    }
    if (invoice?.genre === InvoiceGenres.Invoice) {
      const { _id, ...rest } = invoice;
      setFormValues(rest);
    }
    if (invoice?.genre === InvoiceGenres.Loan) {
      const { invoiceNumber, type, genre, total, subtotal, interest, capital, paymentDue, paymentStatus } = invoice;
      setFormValues({ invoiceNumber, type, genre, total, subtotal, interest, capital, paymentDue, paymentStatus });
    }
    if (invoice?.genre === InvoiceGenres.Tax) {
      const {
        invoiceNumber,
        type,
        genre,
        total,
        paymentDue,
        paymentStatus,
        subtype,
        settlementPeriod,
        settlementPeriodType,
      } = invoice;
      setFormValues({
        invoiceNumber,
        type,
        genre,
        total,
        paymentDue,
        paymentStatus,
        subtype,
        settlementPeriod,
        settlementPeriodType,
      });
    }
  }, [invoice]);

  useEffect(() => {
    if (invoice?.filePath) {
      (async () => {
        const invoiceFile = await handleGetInvoiceInvoice(invoiceId);
        if (invoiceFile) {
          setFile(invoiceFile);
        }
      })();
    }
  }, [invoice?.filePath]);

  return (
    <div className="m-6 flex flex-col h-full 2xl:pace-y-6 xl:space-y-4 space-y-2">
      <div className="h-10 flex flex-row justify-between">
        <div>
          <Text className="2xl:text-28 xl:text-24 text-20 text-text-secondary mr-2.5">{t("Transactions")}</Text>
          <Text className="2xl:text-28 xl:text-24 text-20 text-text-secondary">/</Text>
          <Text className="2xl:text-28 xl:text-24 text-20 ml-2.5 font-sf-pro-bold">{t("Edit-invoice")}</Text>
        </div>
      </div>
      {loading ? (
        <Loader />
      ) : (
        <>
          {[InvoiceGenres.Car, InvoiceGenres.Invoice].includes(invoice?.genre) && (
            <InvoiceForm formValues={{ ...formValues, file }} />
          )}
          {invoice?.genre === InvoiceGenres.Loan && <LoanForm formValues={{ ...formValues }} />}
          {invoice?.genre === InvoiceGenres.Tax && <TaxForm formValues={{ ...formValues }} />}
        </>
      )}
    </div>
  );
};

export default InvoiceEdit;
