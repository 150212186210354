import * as pdfjsLib from "pdfjs-dist";

import { convertBlobToBuffer } from "src/utils/convertBlobToBuffer";

export const useCreateThumbnail = () => {
  const createThumbnail = async (file: Blob, htmlElementId: string) => {
    pdfjsLib.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.5.207/pdf.worker.min.js`;
    const buffer = await convertBlobToBuffer(file);
    const pdfDoc = await pdfjsLib.getDocument({ data: buffer }).promise;
    const page: pdfjsLib.PDFPageProxy = await pdfDoc.getPage(1);
    const div: HTMLElement = document.getElementById(htmlElementId);

    if (div) {
      const existedCanvas: HTMLCanvasElement = div.querySelector("canvas");
      if (existedCanvas) existedCanvas.remove();
      const viewport: pdfjsLib.PDFPageViewport = page.getViewport({ scale: 1 });
      const canvas: HTMLCanvasElement = document.createElement("canvas");
      const context: CanvasRenderingContext2D = canvas.getContext("2d");
      canvas.width = 140;
      canvas.height = 190;
      const scale: number = Math.min(canvas.width / viewport.width, canvas.height / viewport.height);
      const renderContext: pdfjsLib.PDFRenderParams = {
        canvasContext: context,
        viewport: page.getViewport({ scale }),
      };
      page.render(renderContext);
      div.appendChild(canvas);
    }
  };

  return { createThumbnail };
};
