import { useLiquidityAnalysisQuery } from "src/api/graphql/generated/gql-hooks";
import { GetLiquidityAnalysisOptionsInput } from "src/api/graphql/types-and-hooks";
import { useToastError } from "src/common/hooks/useToast";

export const useLiquidityAnalysis = (options: GetLiquidityAnalysisOptionsInput) => {
  const { loading, data } = useLiquidityAnalysisQuery({
    variables: { options },
    onError: () => useToastError("Wystąpił błąd"),
    fetchPolicy: "cache-and-network",
  });
  const liquidity = data?.liquidityAnalysis?.liquidity || [];
  const startValue = data?.liquidityAnalysis?.startValue;

  return {
    liquidityLoading: loading,
    liquidity,
    startValue,
  };
};
