import React, { useEffect, useState } from "react";
import { get } from "lodash";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";

import Text from "src/components/ui/Text";
import Modal from "src/components/ui/Modal";
import AppSwitch from "src/components/form/AppSwitch";

import { InvoiceGenres, InvoiceSubtypes, InvoiceTypes } from "src/api/graphql/types-and-hooks";

import { useCurrentUser } from "src/common/AuthProvider/authProvider.hooks";
import { getInvoicesPath, getPaymentsCalendarPath } from "src/common/router/routerPaths";

import TaxForm from "./forms/TaxForm";
import LoanForm from "./forms/LoanForm";
import InvoiceForm from "./forms/InvoiceForm";
import { useCompanyAccountBalance } from "./invoice.hooks";

const InvoiceAdd = () => {
  const [step, setStep] = useState(1);
  const [showModal, setShowModal] = useState(true);
  const [invoiceType, setInvoiceType] = useState(null);
  const [invoiceGenre, setInvoiceGenre] = useState(null);
  const [invoiceSubtype, setInvoiceSubtype] = useState(null);
  const [showUploadModal, setShowUploadModal] = useState(false);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const currentUser = useCurrentUser();
  const [searchParams] = useSearchParams();
  const { companyAccountBalance } = useCompanyAccountBalance(get(currentUser, "company._id"));

  const CarGenreOptions = [
    { value: InvoiceSubtypes.Fuel, label: t("Fuel") },
    { value: InvoiceSubtypes.Insurance, label: t("Insurance") },
    { value: InvoiceSubtypes.Service, label: t("Service") },
    { value: InvoiceSubtypes.Leasing, label: t("Leasing") },
  ];

  const TaxGenreOptions = [
    { value: InvoiceSubtypes.Zus, label: t("ZUS") },
    { value: InvoiceSubtypes.Vat, label: t("VAT") },
    { value: InvoiceSubtypes.Pit, label: t("PIT") },
    { value: InvoiceSubtypes.Cit, label: t("CIT") },
  ];

  useEffect(() => {
    if (!companyAccountBalance) return;

    if (
      !companyAccountBalance.accountBalance ||
      (companyAccountBalance.taxDetails.isVatPayer && !companyAccountBalance.vatAccountBalance)
    ) {
      navigate(getInvoicesPath());
    }
  }, [companyAccountBalance]);

  const selectType = () => {
    return (
      <Modal
        show={showModal}
        onCancel={() => {
          setShowModal(false);
          navigate(searchParams.get("fromCalendar") ? getPaymentsCalendarPath() : getInvoicesPath());
        }}
        onConfirm={() => {
          if (invoiceType === InvoiceTypes.Income) {
            setInvoiceGenre(InvoiceGenres.Invoice);
            setShowUploadModal(true);
            setStep(3);
          } else {
            setStep(2);
          }
        }}
        confirmText="Next"
        confirmTextClass="text-white"
        cancelTextClass="text-text-secondary"
      >
        <div className="flex flex-col">
          <Text className="2xl:text-42 xl:text-36 text-32 text-white font-sf-pro-medium">{t("Add-transaction")}</Text>
          <Text className="2xl:text-18 xl-text-16 text-14 text-text-secondary mt-3">{t("Select-document-type")}</Text>
          <div className="2xl:mt-10 xl:mt-8 mt-6">
            <AppSwitch
              bgColor="bg-[#F4F4F41A]"
              activeColor="bg-white"
              textClass="text-white 2xl:text-14 xl:text-12 text-10"
              activeTextClass="2xl:text-14 xl:text-12 text-10 text-blue font-sf-pro-bold"
              options={[
                { value: InvoiceTypes.Income, label: "Przychód" },
                { value: InvoiceTypes.Cost, label: "Koszt" },
              ]}
              onChange={(val) => setInvoiceType(val)}
            />
          </div>
        </div>
      </Modal>
    );
  };

  const selectGenre = () => {
    return (
      <Modal
        show={showModal}
        onCancel={() => setStep(1)}
        onConfirm={() => {
          setStep(3);
          if (invoiceGenre !== InvoiceGenres.Car) {
            setShowUploadModal(true);
          }
        }}
        cancelText="Previous"
        confirmText="Next"
        confirmTextClass="text-white"
        cancelTextClass="text-text-secondary"
      >
        <div className="flex flex-col">
          <Text className="2xl:text-42 xl:text-36 text-32 text-white font-sf-pro-medium">{t("Select-cost-genre")}</Text>
          <Text className="2xl:text-18 xl-text-16 text-14 text-text-secondary mt-3">
            {t("Select-document-genre-and-specify-cost-deduction")}
          </Text>
          <div className="2xl:mt-10 xl:mt-8 mt-6">
            <AppSwitch
              bgColor="bg-[#F4F4F41A]"
              activeColor="bg-white"
              textClass="text-white 2xl:text-14 xl:text-12 text-10"
              activeTextClass="2xl:text-14 xl:text-12 text-10 text-blue font-sf-pro-bold"
              options={[
                {
                  value: InvoiceGenres.Invoice,
                  label: t("Invoice-cost"),
                },
                {
                  value: InvoiceGenres.Loan,
                  label: t("Loan-installment"),
                },
                {
                  value: InvoiceGenres.Car,
                  label: t("Car"),
                },
                {
                  value: InvoiceGenres.Tax,
                  label: t("Tax"),
                },
              ]}
              onChange={(val) => setInvoiceGenre(val)}
            />
          </div>
        </div>
      </Modal>
    );
  };

  const selectSubtype = () => {
    return (
      <Modal
        show={showModal}
        onCancel={() => setStep(2)}
        onConfirm={() => {
          setStep(4);
          setShowUploadModal(true);
        }}
        cancelText="Previous"
        confirmText="Next"
        confirmTextClass="text-white"
        cancelTextClass="text-text-secondary"
      >
        <div className="flex flex-col">
          <Text className="2xl:text-42 xl:text-36 text-32 text-white font-sf-pro-medium">{t("Add-document")}</Text>
          <Text className="2xl:text-18 xl-text-16 text-14 text-text-secondary mt-3">{t("Select-document-type")}</Text>
          <div className="2xl:mt-10 xl:mt-8 mt-6">
            <AppSwitch
              bgColor="bg-[#F4F4F41A]"
              activeColor="bg-white"
              textClass="text-white 2xl:text-14 xl:text-12 text-10"
              activeTextClass="2xl:text-14 xl:text-12 text-10 text-blue font-sf-pro-bold"
              options={invoiceGenre === InvoiceGenres.Car ? CarGenreOptions : TaxGenreOptions}
              onChange={(val) => setInvoiceSubtype(val)}
            />
          </div>
        </div>
      </Modal>
    );
  };

  const showInvoiceForm =
    invoiceType === InvoiceTypes.Income || invoiceGenre === InvoiceGenres.Invoice || invoiceGenre === InvoiceGenres.Car;

  return (
    <div className="m-6 flex flex-col h-full 2xl:space-y-6 xl:space-y-4 space-y-2">
      <div className="h-10 flex flex-row justify-between">
        <div>
          <Text className="2xl:text-28 xl:text-24 text-20 text-text-secondary mr-2.5">{t("Transactions")}</Text>
          <Text className="2xl:text-28 xl:text-24 text-20 text-text-secondary">/</Text>
          <Text className="2xl:text-28 xl:text-24 text-20 ml-2.5 font-sf-pro-bold">{t("New-transaction")}</Text>
        </div>
      </div>
      {step === 1 && selectType()}
      {step === 2 && selectGenre()}
      {step === 3 && invoiceGenre === InvoiceGenres.Car && selectSubtype()}
      {step === 3 && invoiceGenre === InvoiceGenres.Tax && selectSubtype()}
      {showInvoiceForm && (
        <InvoiceForm
          formValues={{ type: invoiceType, genre: invoiceGenre, subtype: invoiceSubtype }}
          showUploadModal={showUploadModal}
          onGoBack={() => {
            setShowUploadModal(false);
            setStep((prevState) => (invoiceType === InvoiceTypes.Income ? 1 : prevState - 1));
          }}
        />
      )}
      {invoiceGenre === InvoiceGenres.Loan && <LoanForm formValues={{ type: invoiceType, genre: invoiceGenre }} />}
      {invoiceGenre === InvoiceGenres.Tax && (
        <TaxForm formValues={{ type: invoiceType, genre: invoiceGenre, subtype: invoiceSubtype }} />
      )}
    </div>
  );
};

export default InvoiceAdd;
