import React, { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";

import Text from "src/components/ui/Text";
import Label from "src/components/ui/Label";
import Loader from "src/components/ui/Loader";
import Confirm from "src/components/ui/Confirm";
import AppInput, { InputTypes } from "src/components/form/AppInput";
import AppSelect from "src/components/form/AppSelect";
import AppButton, { ButtonColorTypes } from "src/components/form/AppButton";
import AppCheckbox from "src/components/form/AppCheckbox";

import { useCurrentUser } from "src/common/AuthProvider/authProvider.hooks";

import { formatPrice, subtractPrices } from "src/utils/currency";
import { CarFinancingFormTypes, CarUseFormTypes, CompanyCar } from "src/api/graphql/types-and-hooks";

import PlusIcon from "src/assets/images/plus.svg";
import DeleteIcon from "src/assets/images/delete.svg";

import carSchema from "./car.schema";
import FinancialDetailsWrapper from "./FinancialDetailsWrapper";
import { useAddCarToCompany, useCompanyCars, useDeleteCarFromCompany } from "./financialDetails.hooks";

type CarComponentProps = {
  car: CompanyCar;
};

const Cars = () => {
  const currentUser = useCurrentUser();
  const companyId = currentUser?.company._id;

  const [showConfirm, setShowConfirm] = useState(false);
  const [showAddCarForm, setShowAddCarForm] = useState(false);
  const [carToDeleteId, setCarToDeleteId] = useState<string | null>(null);

  const { t } = useTranslation();
  const FinancingFormOptions = Object.values(CarFinancingFormTypes).map((c) => ({
    label: t(c),
    value: c,
  }));
  const FormOfUseOptions = Object.values(CarUseFormTypes).map((c) => ({ label: t(`CAR_USE_${c}`), value: c }));

  const { cars, loading } = useCompanyCars(companyId);
  const { isAdded, isAdding, handleAddCarToCompany } = useAddCarToCompany();
  const { isDeleting, isDeleted, handleDeleteCarFromCompany } = useDeleteCarFromCompany();

  const {
    handleSubmit,
    register,
    reset,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(carSchema),
    reValidateMode: "onChange",
  });

  useEffect(() => {
    reset({});
    setShowAddCarForm(false);
  }, [isAdded]);

  useEffect(() => {
    if (isDeleted) {
      setShowConfirm(false);
    }
  }, [isDeleted]);

  const renderCars = () => {
    if (!cars) return null;
    return cars.map((car) => {
      return <CarComponent key={car?._id} car={car} />;
    });
  };

  const CarComponent: React.FC<CarComponentProps> = ({ car: { _id, name, subtotal, formOfUse } }) => {
    const { t } = useTranslation();
    const formattedPrice = formatPrice(subtotal, "zł");

    return (
      <div className="bg-bg-light-gray2 rounded-[20px] flex flex-row px-5 2xl:py-5 xl:py-4 py-3 relative">
        <div className="flex flex-col 2xl:space-y-2 xl:space-y-1.5 space-y-1 justify-between">
          <Text className="2xl:text-18 xl:text-16 text-12 font-sf-pro-bold truncate">{name}</Text>
          <Text className="2xl:text-18 xl:text-16 text-12 font-sf-pro-medium text-brand-primary">{`${formattedPrice} netto`}</Text>
          <Text className="2xl:text-14 xl:text-12 text-10 text-text-secondary">{t(`CAR_USE_${formOfUse}`)}</Text>
        </div>
        <div
          className="absolute right-3 cursor-pointer"
          onClick={() => {
            setCarToDeleteId(_id);
            setShowConfirm(true);
          }}
        >
          <img src={DeleteIcon} className="2xl:w-6 xl:w-5 w-4 2xl:h-6 xl:h-5 h-4" />
        </div>
      </div>
    );
  };

  const onSubmit = (data) => {
    data.vat = subtractPrices([data.total, data.subtotal]);

    handleAddCarToCompany(data);
  };

  const onConfirm = () => {
    handleDeleteCarFromCompany({ id: carToDeleteId });
  };

  return (
    <>
      <Confirm show={showConfirm} onCancel={() => setShowConfirm(false)} onConfirm={onConfirm} loading={isDeleting} />
      <FinancialDetailsWrapper>
        <div className="flex flex-col space-y-5">
          {showAddCarForm ? (
            <div className="flex justify-end">
              <div className="px-2">
                <AppButton
                  title={t("Save")}
                  onClick={handleSubmit(onSubmit)}
                  color={ButtonColorTypes.PRIMARY}
                  customClass="rounded-[8px] font-sf-pro-medium"
                  textClass="text-white font-sf-pro-medium"
                  isLoading={isAdding}
                />
              </div>
            </div>
          ) : (
            <div className="2xl:h-10 xl:h-8 h-6"></div>
          )}
          <div className="flex flex-col space-y-6 bg-white rounded-[20px] p-5">
            <Text className="2xl:text-20 xl:text-18 text-16 font-sf-pro-bold">{t("Car-leasing")}</Text>
            {loading ? (
              <Loader />
            ) : (
              <>
                <div className="grid grid-cols-3 gap-4">{renderCars()}</div>
                {!showAddCarForm ? (
                  <div
                    className="2xl:w-[230px] xl:w-[200px] w-[170px] 2xl:py-8 xl:py-6 py-4 border-dashed border-2 border-[#1C1C1C33] rounded-[16px] flex flex-row space-x-2 justify-center items-center cursor-pointer hover:border-blue"
                    onClick={() => setShowAddCarForm(true)}
                  >
                    <img src={PlusIcon} />
                    <Text>{t("Add-car")}</Text>
                  </div>
                ) : undefined}
                {showAddCarForm ? (
                  <div className="flex flex-col xl:space-y-4 space-y-2">
                    <Text className="2xl:text-20 xl:text-18 text-16 font-sf-pro-bold">{t("New-car")}</Text>
                    <div className="flex flex-row space-x-4">
                      <div className="flex flex-col space-y-2 w-[30%] min-w-[250px]">
                        <Label>{t("Car-name")}</Label>
                        <AppInput
                          id="name"
                          register={register}
                          inputType={InputTypes.TEXT}
                          placeholder={t("Car-name")}
                          error={!!errors.name}
                        />
                      </div>
                      <div className="flex flex-col space-y-2 w-[30%] min-w-[250px]">
                        <Label>{t("Financing-form")}</Label>
                        <Controller
                          control={control}
                          name="financingForm"
                          render={({ field }) => (
                            <AppSelect
                              options={FinancingFormOptions}
                              field={field}
                              placeholder="Wybierz..."
                              error={!!errors?.financingForm}
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="flex flex-row space-x-4">
                      <div className="flex flex-col space-y-2 w-[30%] min-w-[250px]">
                        <Label>{t("Car-subtotal")}</Label>
                        <AppInput
                          id="subtotal"
                          register={register}
                          inputType={InputTypes.NUMBER}
                          placeholder={t("Car-subtotal")}
                          error={!!errors.subtotal}
                        />
                      </div>
                      <div className="flex flex-col space-y-2 w-[30%] min-w-[250px]">
                        <Label>{t("Car-total")}</Label>
                        <AppInput
                          id="total"
                          register={register}
                          inputType={InputTypes.NUMBER}
                          placeholder={t("Car-total")}
                          error={!!errors.total}
                        />
                      </div>
                    </div>
                    <div className="flex flex-row space-x-4 items-center">
                      <div className="flex flex-col space-y-2 w-[30%] min-w-[250px]">
                        <Label>{t("Form-of-use")}</Label>
                        <Controller
                          control={control}
                          name="formOfUse"
                          render={({ field }) => (
                            <AppSelect
                              options={FormOfUseOptions}
                              field={field}
                              placeholder="Wybierz..."
                              error={!!errors?.formOfUse}
                            />
                          )}
                        />
                      </div>
                      <div className="flex flex-col space-y-2 w-[30%] min-w-[250px]">
                        <Label>&nbsp;</Label>
                        <AppCheckbox id="isElectric" register={register} label={t("Electric-car")} />
                      </div>
                    </div>
                  </div>
                ) : undefined}
              </>
            )}
          </div>
        </div>
      </FinancialDetailsWrapper>
    </>
  );
};

export default Cars;
