import { useNavigate } from "react-router-dom";

import { useVerifyAccountMutation } from "src/api/graphql/generated/gql-hooks";
import { VerifyAccountInput } from "src/api/graphql/types-and-hooks";

import { getSelectSubscriptionPath } from "src/common/router/routerPaths";
import { useToastError, useToastSuccess } from "src/common/hooks/useToast";

export const useVerifyAccount = () => {
  const navigate = useNavigate();
  const [verifyAccount, { loading }] = useVerifyAccountMutation({
    onCompleted: (data) => {
      useToastSuccess("Pomyślnie zweryfikowano konto");
      localStorage.setItem("companyEmail", data?.verifyAccount?.companyEmail);
      navigate(getSelectSubscriptionPath());
    },
    onError: () => useToastError("Wystąpił błąd podczas weryfikacji konta"),
  });

  return {
    loading,
    handleVerifyAccount: async (verifyAccountInput: VerifyAccountInput) => {
      await verifyAccount({ variables: { verifyAccountInput } });
    },
  };
};
