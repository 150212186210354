import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import NotificationsSidebar from "src/pages/notification/NotificationsSidebar";

import { useCurrentUser } from "src/common/AuthProvider/authProvider.hooks";
import { getSettingsAccountPath } from "src/common/router/routerPaths";

import NotificationIcon from "src/assets/images/notification.svg";

import Text from "./Text";

const AppHeader = () => {
  const [showNotifications, setShowNotifications] = useState(false);
  const [isAnimatingIn, setIsAnimatingIn] = useState(false);
  const [isAnimatingOut, setIsAnimatingOut] = useState(false);

  const user = useCurrentUser();
  const navigate = useNavigate();

  const handleSlideIn = () => {
    setShowNotifications(true);
    setIsAnimatingIn(true);
    setTimeout(() => {
      setIsAnimatingIn(false);
    }, 500);
  };

  const handleSlideOut = () => {
    setIsAnimatingOut(true);
    setTimeout(() => {
      setIsAnimatingOut(false);
      setShowNotifications(false);
    }, 500);
  };

  return (
    <>
      {showNotifications ? (
        <div className="absolute w-[350px] z-10 right-0 2xl:mt-[91px] xl:mt-[81px] mt-[71px] 2xl:h-[calc(100%-91px)] xl:h-[calc(100%-81px)] h-[calc(100%-71px)] overflow-y-hidden overflow-x-hidden">
          <NotificationsSidebar isAnimatingIn={isAnimatingIn} isAnimatingOut={isAnimatingOut} />
        </div>
      ) : undefined}
      <div className="2xl:h-[91px] xl:h-[81px] h-[71px] border-b-[1.5px] border-light-gray-2 flex justify-end items-center">
        <div className="mr-12 flex flex-row gap-x-4 cursor-pointer">
          <div
            className="rounded-full bg-gray-200 2xl:w-[42px] xl:w-[38px] w-[34px] 2xl:h-[42px] xl:h-[38px] h-[34px] flex justify-center items-center cursor-pointer"
            onClick={() => (showNotifications ? handleSlideOut() : handleSlideIn())}
          >
            <img src={NotificationIcon} />
          </div>
          <div
            className="rounded-full bg-gray-200 2xl:w-[42px] xl:w-[38px] w-[34px] 2xl:h-[42px] xl:h-[38px] h-[34px] flex justify-center items-center"
            onClick={() => navigate(getSettingsAccountPath())}
          >
            <Text className="font-sf-pro-bold">{`${user?.firstName[0]} ${user?.lastName[0]}`}</Text>
          </div>
          <div className="flex flex-col justify-center" onClick={() => navigate(getSettingsAccountPath())}>
            <Text className="font-sf-pro-bold">{`${user?.firstName} ${user?.lastName}`}</Text>
            <Text className="text-text-secondary">{user?.email}</Text>
          </div>
        </div>
      </div>
    </>
  );
};

export default AppHeader;
