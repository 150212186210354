import React, { useEffect, useState } from "react";
import moment from "moment";

import ModuleHeader from "src/components/ui/ModuleHeader";
import ModuleWrapper from "src/components/ui/ModuleWrapper";
import TimeRangeToolbar, { CalendarView } from "src/components/ui/TimeRangeToolbar";

import { useCurrentUser } from "src/common/AuthProvider/authProvider.hooks";
import { FinancialMinimum, SortOptions } from "src/api/graphql/types-and-hooks";

import Summary from "../paymentCalendar/Summary";
import { useInvoices } from "../invoice/invoice.hooks";
import { useGetUser } from "../settings/settings.hooks";

import Chart from "./Chart";
import FinancialMinimumModal from "./FinancialMinimumModal";
import { useLiquidityAnalysis } from "./liquidityAnalysis.hooks";

const LiquidityAnalysis = () => {
  const loggedUser = useCurrentUser();
  const { user } = useGetUser(loggedUser?._id);

  const [showModal, setShowModal] = useState(false);
  const [momentDate, setMomentDate] = useState(moment());
  const [calendarView, setCalendarView] = useState(CalendarView.Week);
  const [financialMinimum, setFinancialMinimum] = useState<FinancialMinimum[]>([]);
  const [paymentDue, setPaymentDue] = useState<{ gte: number; lte: number }>({
    gte: moment().startOf("day").unix(),
    lte: moment().endOf("day").unix(),
  });

  const { invoices, loading } = useInvoices({
    take: 0,
    skip: 0,
    filter: { paymentDue: paymentDue },
    sort: { paymentDue: SortOptions.Desc },
  });

  const { liquidity, startValue } = useLiquidityAnalysis({
    filter: { paymentDue: paymentDue },
  });

  useEffect(() => {
    if (user?.settings?.financialMinimum) setFinancialMinimum(user?.settings?.financialMinimum);
  }, [user?.settings?.financialMinimum]);

  useEffect(() => {
    const updatePaymentDue = () => {
      if (calendarView === CalendarView.Week) {
        setPaymentDue({
          gte: moment(momentDate).startOf("isoWeek").unix(),
          lte: moment(momentDate).endOf("isoWeek").unix(),
        });
      }
      if (calendarView === CalendarView.Month) {
        setPaymentDue({
          gte: moment(momentDate).startOf("month").unix(),
          lte: moment(momentDate).endOf("month").unix(),
        });
      }
    };
    updatePaymentDue();
  }, [momentDate]);

  return (
    <>
      {showModal && <FinancialMinimumModal show={showModal} onCancel={() => setShowModal(false)} />}
      <ModuleWrapper className="space-y-4">
        <ModuleHeader
          className="mb-0"
          title="Liquidity-analysis"
          addButtonTitle="Set-minimum-level"
          onAdd={() => setShowModal(true)}
        />
        <div className="basis-2/3 2xl:min-h-[500px] xl:min-h-[450px] min-h-[400px] max-h-2/3 bg-white rounded-[20px] p-3">
          <TimeRangeToolbar
            calendarView={calendarView}
            calendarViews={[
              { value: CalendarView.Week, label: "Tydzień" },
              { value: CalendarView.Month, label: "Miesiąc" },
            ]}
            onCalendarViewChange={setCalendarView}
            onDateChange={setMomentDate}
          />
          <div className="h-[90%] w-full">
            <Chart
              data={liquidity}
              startValue={startValue}
              minimum={financialMinimum}
              startDate={moment(paymentDue.gte * 1000)}
              endDate={moment(paymentDue.lte * 1000)}
              documents={invoices}
            />
          </div>
        </div>
        <div className="flex flex-row space-x-4 xl:min-h-[250px] min-h-[200px] h-[35%] max-h-[35%]">
          <Summary calendarView={calendarView} documents={invoices} isLoading={loading} />
        </div>
      </ModuleWrapper>
    </>
  );
};

export default LiquidityAnalysis;
