import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";

import Text from "src/components/ui/Text";
import Loader from "src/components/ui/Loader";
import Confirm from "src/components/ui/Confirm";
import AppSelect from "src/components/form/AppSelect";
import AppButton, { ButtonColorTypes } from "src/components/form/AppButton";

import { useCurrentUser } from "src/common/AuthProvider/authProvider.hooks";
import { CompanyLegalFormTypes } from "src/api/graphql/types-and-hooks";

import legalFormSchema from "./legalForm.schema";
import FinancialDetailsWrapper from "./FinancialDetailsWrapper";
import { useCompanyLegalForm, useEditCompany } from "./financialDetails.hooks";

const LegalForm = () => {
  const { t } = useTranslation();
  const LegalFormOptions = Object.values(CompanyLegalFormTypes).map((c) => ({ label: t(c), value: c }));

  const [showConfirm, setShowConfirm] = useState(false);

  const currentUser = useCurrentUser();
  const companyId = currentUser?.company._id;
  const { legalForm, loading } = useCompanyLegalForm(companyId);

  const {
    handleSubmit,
    reset,
    control,
    getValues,
    formState: { errors, isDirty },
  } = useForm({
    resolver: yupResolver(legalFormSchema),
    reValidateMode: "onChange",
  });
  const { isUpdating, isUpdated, handleEditCompany } = useEditCompany();

  useEffect(() => {
    setShowConfirm(false);
  }, [isUpdated]);

  useEffect(() => {
    reset({ legalForm });
  }, [legalForm]);

  const onConfirm = () => {
    const legalForm = getValues("legalForm");

    handleEditCompany({ id: companyId, legalForm });
  };

  return (
    <>
      <Confirm
        show={showConfirm}
        title={t("Confirm-update-title")}
        onCancel={() => setShowConfirm(false)}
        onConfirm={onConfirm}
        loading={isUpdating}
      />
      <FinancialDetailsWrapper>
        <div className="flex flex-col space-y-5">
          <div className="flex justify-end">
            <div className="px-2">
              <AppButton
                title={t("Save")}
                onClick={handleSubmit(() => setShowConfirm(true))}
                color={ButtonColorTypes.PRIMARY}
                customClass="rounded-[8px] font-sf-pro-medium"
                disabled={!isDirty}
                textClass="text-white font-sf-pro-medium"
              />
            </div>
          </div>
          <div className="bg-white rounded-[20px] p-5">
            <div className="flex flex-col space-y-6">
              <Text className="2xl:text-20 xl:text-18 text-16 font-sf-pro-bold">{t("Legal-form")}</Text>
              {loading ? (
                <Loader />
              ) : (
                <div className="flex flex-col space-y-4 w-[50%]">
                  <Text className="2xl:text-18 xl:text-16 text-12">{t("Select-company-legal-form")}</Text>
                  <Controller
                    control={control}
                    name="legalForm"
                    render={({ field }) => (
                      <AppSelect
                        options={LegalFormOptions}
                        field={field}
                        placeholder="Wybierz..."
                        error={!!errors?.legalForm}
                      />
                    )}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </FinancialDetailsWrapper>
    </>
  );
};

export default LegalForm;
