import axios from "axios";

import { API_URL } from "src/api/apollo/client";

export const useGetInvoiceFile = () => {
  const getInvoiceFile = async (id: string): Promise<Blob> => {
    const file = await getFile(id, "/invoice/download");
    return file;
  };

  return { handleGetInvoiceInvoice: getInvoiceFile };
};

export const useGetOrderInvoiceFile = () => {
  const getOrderInvoiceFile = async (id: string): Promise<Blob> => {
    const file = await getFile(id, "/order/download");
    return file;
  };

  return { handleGetOrderInvoice: getOrderInvoiceFile };
};

const getFile = async (id: string, path: string): Promise<Blob> => {
  const accessToken = localStorage.getItem("accessToken");
  const response = await axios.get(`${API_URL}${path}/${id}/`, {
    responseType: "blob",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  const blob = new Blob([response.data], {
    type: "application/pdf",
  });
  return blob;
};
