import { boolean, date, mixed, number, object, string } from "yup";

import { InvoiceGenres, InvoiceSubtypes, InvoiceTypes, PaymentStatus } from "src/api/graphql/types-and-hooks";

export default object({
  invoiceNumber: string().required(),
  billFrom: object({
    name: string().required(),
    address: string().when("type", ([type], schema) => {
      return type === InvoiceTypes.Income ? schema.required() : schema.nullable();
    }),
    city: string().when("type", ([type], schema) => {
      return type === InvoiceTypes.Income ? schema.required() : schema.nullable();
    }),
    postcode: string().when("type", ([type], schema) => {
      return type === InvoiceTypes.Income ? schema.required() : schema.nullable();
    }),
    country: string().when("type", ([type], schema) => {
      return type === InvoiceTypes.Income ? schema.required() : schema.nullable();
    }),
    companyId: string().when("type", ([type], schema) => {
      return type === InvoiceTypes.Income ? schema.required() : schema.nullable();
    }),
  }).required(),
  billTo: object({
    name: string().required(),
    address: string().required(),
    city: string().required(),
    postcode: string().required(),
    country: string().nullable(),
    companyId: string().required(),
  }).required(),
  saleDate: date().required(),
  paymentDue: date().required(),
  paymentDate: date().when("paymentStatus", ([paymentStatus], schema) => {
    return paymentStatus === PaymentStatus.Paid ? schema.required() : schema.nullable();
  }),
  issueDate: date()
    .when("paymentDue", ([paymentDue], schema) => {
      return paymentDue ? schema.max(paymentDue) : schema;
    })
    .required(),
  car: string().when("genre", ([genre], schema) => {
    return genre === InvoiceGenres.Car ? schema.required() : schema.nullable();
  }),
  subtotal: number().required(),
  total: number().required(),
  description: string(),
  type: mixed<InvoiceTypes>().oneOf(Object.values(InvoiceTypes)).required(),
  genre: mixed<InvoiceGenres>().oneOf(Object.values(InvoiceGenres)).required(),
  subtype: mixed<InvoiceSubtypes>().oneOf(Object.values(InvoiceSubtypes)).nullable(),
  file: mixed<Blob>().nullable(),
  paymentStatus: mixed<PaymentStatus>().oneOf(Object.values(PaymentStatus)).required(),
  splitPayment: boolean().default(false),
  capital: number().when("subtype", ([subtype], schema) => {
    return [InvoiceSubtypes.Insurance].includes(subtype) ? schema.required() : schema.nullable();
  }),
  interest: number().when("subtype", ([subtype], schema) => {
    return [InvoiceSubtypes.Leasing, InvoiceSubtypes.Insurance].includes(subtype)
      ? schema.required()
      : schema.nullable();
  }),
}).required();
